import React from "react";
import {
  SEO,
  MarkdownContent,
  Image,
  BasicCarousel,
  componentIterator,
} from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class GalleryReviewsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.pacificartistry.com/gallery-reviews/"}
        />
        <Grid
          className={"custom-page-gallery-reviews custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"ouslufgqsuaewggm"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
            columns={1}
          >
            <Grid.Column
              className={"yoiwjwnormhlazkr"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "c5c5bd60-9051-408b-a579-14fec6511a0e",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"vafhizjtffbfezug"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"top"}
          >
            <Grid.Column
              className={"ixluswprzsufnchs"}
              style={{ padding: 16 }}
              width={4}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "69a398bf-d104-47d6-b3c2-60c47f197216",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"qgsjcjfutrwydpie"}
              style={{ padding: 16 }}
              width={4}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "3b75cc47-fdff-43e9-9b77-b32c45f1ba74",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"lkeaxapmiyoyjkzs"}
              style={{ padding: 16 }}
              width={4}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "d5eb0d54-8616-473e-894b-b3f4673454b0",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"drfnjmhuzguxgebs"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"bosccjspgsdntnjf"}
              style={{ padding: 16 }}
              width={12}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "c89b2448-226e-44fa-8f8c-0d93b6bf551a",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"cesgnzldknewgnsp"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"top"}
          >
            <Grid.Column
              className={"wvfrjuytcbtopshi"}
              style={{ padding: 16 }}
              width={4}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "a278da72-23a9-4639-a966-ba4e6bf8076e",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"acpiwjfalitysqrz"}
              style={{ padding: 16 }}
              width={4}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "4948da40-2308-42da-ad92-d8c5e2acac3f",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"ripicgpaknoynhor"}
              style={{ padding: 16 }}
              width={4}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "6f9fe8a5-d4a1-4adf-bb54-a48cc2aec654",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"dkzkwkkiqeeczjxv"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column className={"ndlacffvcjwvonzp"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <BasicCarousel
                  className={"image-block"}
                  style={{}}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "BasicCarousel",
                      componentIdentifier:
                        "5c709dd0-601e-4f1e-bb14-8362dc3a16b6",
                    }),
                    component: <Image className={"image"} />,
                    propMap: { src: "__all__" },
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 29270 }) {
      title
      seoTitle
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
